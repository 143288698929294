import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Container from '@objects/container'
import Stage from '@components/stage'
import Copy from '@objects/copy'

function RootIndex({ pageContext }) {
  return (
    <>
      <Stage
        alignTop={true}
        type={'generic'}
        title={pageContext.page.title}
        ariaLabel={pageContext.page.title}
      />
      <Container ariaLabel="arialabel.easylanguage">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '80px auto',
          }}
        >
          <Copy
            type="article"
            html={
              pageContext.modules[0].contentfulfields[0].value
                .childMarkdownRemark.html
            }
            component="div"
          />
        </div>
      </Container>
    </>
  )
}

RootIndex.propTypes = {
  pageContext: PropTypes.object,
}

export default RootIndex

export const pageQuery = graphql`
  query {
    allContentfulPage(
      filter: { id: { eq: "eb2e077b-2af5-5db7-ad94-8bf86d8550c4" } }
    ) {
      nodes {
        node_locale
        id
        slug
        title
        modules {
          key
          contentfulfields {
            ... on ContentfulStaticField {
              __typename
              key
              value {
                childMarkdownRemark {
                  html
                }
              }
              link
              media {
                fluid(quality: 80, maxWidth: 1920) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
                file {
                  contentType
                  fileName
                  url
                  details {
                    size
                  }
                }
                title
                description
              }
            }
          }
        }
      }
    }
  }
`
